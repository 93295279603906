<template>
  <div>
    <el-button type="success" round
      @click="showAddPickupLocationModal"
    >Add Tour Type</el-button>
    <article>
      <section>
        <div class="tour-types_dialogs">
          <el-dialog
            :title="`Add Tour Type`"
            :visible.sync="addTourTypeModal"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="submitting">
              <el-form
                :model="tourTypeForm"
                :rules="rules"
                label-position="top"
                ref="tourTypeForm"
                class="demo-tourTypeForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <el-form-item
                      label="Tour Type Title"
                      prop="tourIdeaTypeTitle"
                    >
                      <el-input v-model="tourTypeForm.tourIdeaTypeTitle"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                  type="success" round
                    @click="addTourType('tourTypeForm')"
                  >Add Tour Type</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2024 Bakyala Safaris, All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--v-model="tourTypeForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isUploadingFile: false,
      addTourTypeModal: false,
      submitting: false,
      tourTypeForm: {
        tourIdeaTypeTitle: "",
      },
      rules: {
        tourIdeaTypeTitle: [
          {
            required: true,
            message: "Tour Type Title is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    showAddPickupLocationModal() {
      this.addTourTypeModal = true;
      this.tourTypeForm.tourIdeaTypeTitle = "";
    },
    async addTourType(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            this.isUploadingFile = true;
            let response = await this.$http.post(`tour-types`, {
              tourIdeaTypeTitle: this.tourTypeForm.tourIdeaTypeTitle,
              createdBy: this.$store.state.userId,
            });
            if (
              response.data.success &&
              response.data.message == "TOUR_TYPE_CREATED_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.$emit("re-fresh");
              this.$notify({
                title: "Success",
                message: "Tour Type added successfully",
                type: "success",
              });
              this.addTourTypeModal = false;
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Add Tour Type",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>