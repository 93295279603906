<template>
  <div>
    <article>
      <section>
        <div class="tour-types_dialogs">
          <el-dialog
            :title="`Update Pickup Location`"
            :visible.sync="isEditPickupLocationModalVisible"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="submitting">
              <el-form
                :model="pickupLocationForm"
                :rules="rules"
                label-position="top"
                ref="pickupLocationForm"
                class="demo-pickupLocationForm2"
              >
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item
                      label="Pickup Location Title"
                      prop="pickup_title"
                    >
                      <el-input v-model="pickupLocationForm.pickup_title"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    style="background-color: #083628; color: white;"
                    @click="editPickupLocation('pickupLocationForm')"
                  > Save Pickup Location Changes</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2024 Bakyala Safaris, All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--v-model="pickupLocationForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      isUploadingFile: false,

      isEditPickupLocationModalVisible: false,
      loadingError: false,
      submitting: false,
      pickupLocationForm: {
        pickup_title: "",
      },

      rules: {
        pickup_title: [
          {
            required: true,
            message: "First Name is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  props: {
    showTourTypeEditDialog: {
      required: true,
      type: Boolean,
    },
    pickUpLocationData: {
      required: true,
      type: Object,
    },
  },

  watch: {
    showTourTypeEditDialog() {
      if (this.showTourTypeEditDialog === true) {
        this.isEditPickupLocationModalVisible = true;
        this.pickupLocationForm.pickup_title =
          this.pickUpLocationData.pickup_title;
      } else {
        this.isEditPickupLocationModalVisible = false;
      }
    },
  },

  methods: {
    handleClose(done) {
      done();
      this.$emit("closeEditPickupLocationDialog");
    },

    async editPickupLocation(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            this.isUploadingFile = true;
            let response = await this.$http.patch(
              `tour-types/${this.pickUpLocationData.pickup_id}`,
              { pickup_title: this.pickupLocationForm.pickup_title }
            );
            if (
              response.data.success &&
              response.data.message == "PICKUP_LOCATION_UPDATED_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.$emit("re-fresh");
              this.$notify({
                title: "Success",
                message: "Pickup Location updated successfully",
                type: "success",
              });
              this.$emit("closeEditPickupLocationDialog");
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to update Pickup Location",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>